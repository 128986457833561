<template>
  <div class="ios">
    <div class="ios-title">{{viewData.title}}</div>
    <div class="content" v-html="viewData.content" v-loading="loading" element-loading-text="拼命加载中" style="min-height: 100px;"></div>
    <!--<div>{{viewData.title}}</div>-->

    <div class="footer">
      <div style="margin-bottom: 18px;" v-if="viewData.preArticle" @click="otherArticle(viewData.preArticle.id)">上一篇：{{viewData.preArticle.title}}</div>
      <div v-if="viewData.nextArticle" @click="otherArticle(viewData.nextArticle.id)">下一篇： {{viewData.nextArticle.title}}</div>
    </div>
  </div>
</template>

<script>
  import PubSub from 'pubsub-js'
  import {queryById} from "@/api/request";

  export default {
    name: "Ios",
    data(){
      return {
        viewData:{},
        loading:true
      }
    },
    mounted(){
      // console.log(this.$route);
      PubSub.subscribe('toSubView',(msg,data)=>{
        this.loading = true;
        // console.log('ios-----',msg, data);//  #303133
        // this.getQueryById({id:this.$route.query.id || data.id});
        if(this.$route.query.id){
          this.getQueryById({id:this.$route.query.id});
          // this.viewData = JSON.parse(JSON.stringify(data));
        }else {
          this.getQueryById({id: data.id});
        }

      })
    },
    methods:{
      getQueryById(id){
        let that = this;
        queryById(id).then(res=>{
          if(res.code == 0 || res.code ==200){
            that.viewData = res.result;
            // console.log('that.viewData',that.viewData);
          }
          this.loading = false;
        })


      },
      otherArticle(id){
        this.getQueryById({id});
        this.$router.push({name:'Ios',query:{id}});
        PubSub.publish('updataId',id); // 同步更新左侧激活状态
      },

    }
  };
</script>

<style lang="less" scoped>
.ios{
  /*padding-left: 30px;*/
  background: #fff;
  .ios-title{
    height: 52px;
    line-height: 52px;
    background: rgba(216,216,216,0);
    box-shadow: 0px 1px 0px 0px #ebedf0 inset;
    padding-left: 30px;
    font-size: 16px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #2f3133;

  }
  .content{
    padding: 17px 30px 20px 30px;
    border-top:  solid 1px #e6e6e6;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: left;
    color: #5c6066;
    line-height: 26px;
    /deep/ img{
      /*width: 100%;*/
    }
  }
  .footer{
    /*height: 86px;*/
    background: rgba(216,216,216,0);
    box-shadow: 0px 1px 0px 0px #ebedf0 inset;
    padding: 20px 0 20px 30px;
    div:hover{
      cursor: pointer;
      color: #0F80DC;
    }
  }
}
</style>
